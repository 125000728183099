<template>
    <div>
      <Toolbar :handle-reset="resetForm" :handle-submit="onCreateSeminarPackage" />
      <SeminarPackageForm
        :errors="violations"
        :values="item"
        ref="createForm"
        refForm="createForm"
        :loading="isLoading"
      />
      <Loading :visible="isLoading" />
    </div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import { createHelpers } from "vuex-map-fields";
  import SeminarPackageForm from "../../components/seminarPackage/Form.vue";
  import Loading from "../../components/Loading";
  import Toolbar from "../../components/Toolbar";
  import CreateMixin from "../../mixins/CreateMixin";
  import { mapGetters } from "vuex";
  
  const servicePrefix = "SeminarPackage";
  
  const { mapFields } = createHelpers({
    getterType: "seminarPackage/getField",
    mutationType: "seminarPackage/updateField",
  });
  
  export default {
    name: "SeminarPackageCreate",
    servicePrefix,
    mixins: [CreateMixin],
    components: {
      Loading,
      Toolbar,
      SeminarPackageForm,
    },
    data() {
      return {
        item: {},
        parent: {},
      };
    },
    computed: {
      ...mapFields(["error", "isLoading", "created", "deleted", "violations"]),
      ...mapGetters("seminarPackage", ["find"]),
    },
    methods: {
      ...mapActions("seminarPackage", {
        create: "create",
      }),
      onCreateSeminarPackage() {
        if (this.$route.params.parent) {
          this.$refs.createForm.item.parent = this.$route.params.parent;
        }
        this.onSendForm();
      },
    },
    created() {
      this.parent = this.$route.params.parent
        ? this.find(this.$route.params.parent)
        : {};
    },
  };
  </script>
  